<template>
  <div>
    <!-- <nav-tabs v-if="user.isLogin" :options="userTypes">
      <template v-slot:profile="{ item }">
        <fa :icon="['far', 'user']" />
        {{ item.name }}
      </template>
      <template v-slot:inventories="{ item }">
        <fa :icon="['fa', 'boxes']" />
        {{ item.name }}
      </template>
      <template v-slot:transactions="{ item }">
        <fa :icon="['fa', 'receipt']" />
        {{ item.name }}
      </template>
    </nav-tabs> -->

    <b-modal id="order-success" size="md" centered hide-header hide-footer>
      <div class="text-right">
        <button class="btn btn-link" style="font-size: 22px;" @click="$bvModal.hide('order-success')">
          <fa :icon="['far', 'times-circle']" />
        </button>
      </div>
      <div class="order">
        <h5 class="text-white">
          取货成功，您的饰品已经在路上
        </h5>
        <p class="order__desc text-center pl-5 pr-5">
          正在等待卖家发送STEAM报价
        </p>
        <long-item :inventory="currentInventory" />
      </div>
    </b-modal>

    <div v-if="user.isLogin" class="container mt-4">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { UserService, getPropValue } from '@/services/api'
import { STEAM_LOGIN_URL } from '@/services/config'
// import NavTabs from './NavTabs.vue'

const TYPES = [
  { name: '有效饰品', id: 'available' },
  // { name: '已交易', id: 'sold' },
  { name: '已提取', id: 'withdrawn' },
  { name: '已回收', id: 'recycled' },
]

// const USER_TYPES = [
//   { name: '个人信息', id: 'profile', path: '/user/profile' },
//   { name: '库存', id: 'inventories', path: '/user/inventories' },
//   { name: '充值记录', id: 'transactions', path: '/user/transactions' },
// ]

export default {
  name: 'Inventory',
  components: {
    // NavTabs,
  },
  data() {
    return {
      tab: TYPES[0],
      // userTab: USER_TYPES[0],
      types: TYPES,
      // userTypes: USER_TYPES,
      inventories: [],
      currentInventory: null,
      keyword: '',
      currentPage: 1,
      perPage: 24,
      loading: false,
      selectedItems: [],
    }
  },
  computed: {
    ...mapGetters(['user', 'app']),
  },
  watch: {
    currentPage() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
  },
  mounted() {},
  methods: {
    toast(message, type = 'warning') {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        variant: type,
      })
    },
    login() {
      window.location = STEAM_LOGIN_URL
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';

.steam__login {
  &:hover {
    box-shadow: 0 0 15px #6242da;
  }

  transition: all ease 0.4s;
  background-image: linear-gradient(225deg, #9b77ef 0%, #6242da 100%);
  border-radius: 12px 2px 12px 2px;
  font-size: 16px;
  border: none;
  color: white;
  padding: 1em;
}

.no-login {
  &__text {
    margin-left: -100px;
  }

  &__img {
    flex: 0 0 700px;
    min-height: 600px;

    background-size: contain;
    background-repeat: no-repeat;
    justify-content: center;
    background-position: left center;
    background-image: url('../../assets/no-login.png');
  }

  display: flex;
  padding: 2em;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}

.login-btn {
  margin: 2em;
  flex: 0 0 200px;
}

.order {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__desc {
    color: #6a6996;
    font-size: 12px;
  }
}

.tabs {
  display: flex;
  border-bottom: solid 1.5px #28283a;
}

.inventories {
  display: flex;
  flex-wrap: wrap;
}

.tab {
  padding: 0.5em;
  font-size: 18px;

  &.active {
    color: #fff;
    border-bottom: solid 2px $primary;
  }

  border-bottom: solid 2px transparent;
}

@media screen and (min-width: 1080px) {
  .inventory-item {
    flex: 1 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 760px) and (max-width: 1080px) {
  .inventory-item {
    flex: 1 33.33333%;
    max-width: 33.33333%;
  }
}

@media screen and (min-width: 520px) and (max-width: 760px) {
  .inventory-item {
    flex: 1 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 520px) {
  .inventory-item {
    flex: 1 100%;
    max-width: 100%;
  }
}
</style>
